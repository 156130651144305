import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

const App = () => {
  // Stavy pro zadávání údajů uživatelem
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Funkce pro zpracování přihlášení uživatele
  const handleLogin = async (isGuest = false) => {
    if (isGuest) {
      console.log("Přihlášen jako host");
      setLoggedIn(true);
    } else {
      try {
        const response = await axios.post('https://pvprng.a24vps.com:5000/login', {
          email,
          password,
        });
        console.log(response.data.message);
        setLoggedIn(true);
      } catch (err) {
        console.error(err.response?.data?.message || 'Chyba při přihlášení');
        setErrorMessage(err.response?.data?.message || 'Chyba při přihlášení');
      }
    }
  };

  // Funkce pro zpracování registrace uživatele
  const handleRegister = async () => {
    try {
      const response = await axios.post('https://pvprng.a24vps.com:5000/register', {
        email,
        password,
      });
      console.log(response.data.message);
      alert('Registrace proběhla úspěšně, nyní se můžete přihlásit');
    } catch (err) {
      console.error(err.response?.data?.message || 'Chyba při registraci');
      setErrorMessage(err.response?.data?.message || 'Chyba při registraci');
    }
  };

  // Obsah pro přihlášené uživatele
  if (loggedIn) {
    return (
      <div className="game-menu">
        <h1>Vítejte v Herním Menu!</h1>
        <p>Vyberte si hru, kterou chcete hrát:</p>
        {/* Zde můžeme přidat komponenty pro hlavní menu her */}
      </div>
    );
  }

  // Přihlašovací a registrační formulář
  return (
    <div className="login-container">
      <h1>Přihlášení</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Heslo"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={() => handleLogin()}>Přihlásit se</button>
      <button onClick={() => handleLogin(true)}>Přihlásit se jako host</button>
      <button onClick={handleRegister}>Registrace</button>
    </div>
  );
};

export default App;
